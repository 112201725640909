import React from "react"

const HamburgerMenuIcon = () => (
  <svg
    className='fill-current h-3 w-3'
    viewBox='0 0 20 20'
    xmlns='https://www.w3.org/2000/svg'
  >
    <title>Menu</title>
    <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
  </svg>
)

export default HamburgerMenuIcon
